import axios from 'axios';
import {
  type ResourceType,
  type Resource,
  type ResourceQueryParams,
  type UserResource,
  type UserResourceTemplate,
} from './room-inventory.service.types';

const backendUrl = import.meta.env.VITE_ROOM_INVENTORY_URL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const roomInventoryApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});

export const roomInventoryService = {
  getResourcesByType(params: ResourceQueryParams) {
    const { resourceType, limit = 50, offset = 0, priced } = params;
    const pricedParam =
      priced !== undefined ? `&priced=${priced ? 'true' : 'false'}` : '';
    return roomInventoryApi.get<Resource[]>(
      `resource/byType?limit=${limit.toString()}&offset=${offset.toString()}&type=${resourceType}${pricedParam}`,
    );
  },
  getResourceById(id: string) {
    return roomInventoryApi.get<Resource>(`resource/${id}`);
  },
  getMyResourcesByType(type: ResourceType) {
    return roomInventoryApi.get<UserResource[]>(`userResource?type=${type}`);
  },
  getUserResourceTemplates(userId: string) {
    return roomInventoryApi.get<UserResourceTemplate[]>(
      `/userResource/templates?userId=${userId}`,
    );
  },
  getPurchaseToken: async (resourceId: string, quantity: number) => {
    return await roomInventoryApi.post<{ token: string }>('resource/purchase', {
      resourceId,
      quantity,
    });
  },
  confirmPurchase: async (
    resourceId: string,
    quantity: number,
    token: string,
  ) => {
    return await roomInventoryApi.post<{ message: string }>(
      'resource/purchase/confirm',
      {
        resourceId,
        quantity,
        token,
      },
    );
  },
};
