import { showcaseService } from '@/services';
import type {
  UserRoom,
  UserRoomProductType,
  VirtualProduct,
} from '@/services/showcase.service.types';
import { handleAxiosError } from '@/utils/error';
import { toast } from '@/utils/toast';
import { defineStore } from 'pinia';
import { useRoomInventoryStore } from './room-inventory';

export interface Showcase {
  rooms?: UserRoom[];
  product?: VirtualProduct;
}

export const useShowcaseStore = defineStore('showcase', {
  state: (): Showcase => ({
    rooms: undefined,
    product: undefined,
  }),
  actions: {
    async getMyRooms() {
      try {
        const res = await showcaseService.getMyRooms();
        this.rooms = res.data;
      } catch (err) {
        const { message } = handleAxiosError(err);
        toast.error({
          title: 'There was an error getting your rooms',
          message: message,
        });
      }
    },
    async createRoom(type: UserRoomProductType, templateId?: string) {
      const res = await showcaseService.createMyRoom(type, templateId);
      await this.getMyRooms();
      await useRoomInventoryStore().getMyTemplates();
      return res.data;
    },
    clearProduct() {
      this.product = undefined;
    },
  },
});

export default useShowcaseStore;
