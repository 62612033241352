export const resourceType = {
  Asset: 'ASSET',
  Template: 'TEMPLATE',
} as const;

export type ResourceType = (typeof resourceType)[keyof typeof resourceType];

export interface Resource {
  id: string;
  name: string;
  description: string;
  unityId: number;
  price: number;
  availableQuantity: number;
  isLimited: boolean;
  type: string;
  images: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface ResourceQueryParams {
  resourceType: ResourceType;
  limit?: number;
  offset?: number;
  priced?: boolean;
}

export interface UserResource {
  id: string;
  userId: string;
  resourceId: string;
  unityId: number;
  quantityInUse: number;
  quantityPurchased: number;
  createdAt: Date;
  updatedAt: Date;
  resource: Resource;
}

export interface UserResourceTemplate {
  id: string;
  resource: {
    id: string;
    name: string;
    images: string[];
  };
}
